/* We use this to get basic styling on all basic form elements */

@import "mixins";

$button-default-background: $secondary-green !default;
$button-default-border-color: $secondary-green-hover !default;

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea {
	box-sizing: border-box;
	display: block;
	width: 100%;
	height: 30px;
	padding: 0 0.5em;
	margin: 0 0 1em;
	font-family: inherit;
	font-size: 0.875em;
	color: $black;
	background-color: $gray-light;
	border: 1px solid $gray-placeholder;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
	transition: box-shadow 0.45s ease 0s, border-color 0.45s ease-in-out 0s;
}

BUTTON, .button {
	position: relative;
	display: inline-block;
	height: 30px;
	padding: 0 0.5em;
	font-family: inherit;
	font-size: 1em;
	line-height: 1;
	color: $white;
	text-align: center;
	text-decoration: none;
	background-color: $button-default-background;
	border: 1px solid #{$button-default-border-color};
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;

	@include sf_pro_textsemibold;
}



